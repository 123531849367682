import { faArrowsTurnToDots } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@vetahealth/fishing-gear/colors'
import { getDisplayValue, normalizedUnits } from '@vetahealth/fishing-gear/conversions'
import { isTruthy } from '@vetahealth/fishing-gear/lib/typeguards'
import { PatientDetails, SummaryIntervalEnum } from '@vetahealth/tuna-can-api'
import { Button, Radio, RadioChangeEvent, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { groupBy, isEmpty } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { useResultsStore } from '../../../../stores/results'
import { useUserStore } from '../../../../stores/user'
import { Spinner } from '../../../Spinner'
import { getTrackingTitle, isTrackingType } from '../../helpers'
import { NoData } from '../../styles'
import { Actions, CreateVitalsReport, ReviewBulk } from './Actions'

const Description = styled.i`
  position: absolute;
  right: 0;
`

const Table = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-top: 12px;
  background-color: ${({ theme }) => theme.tableBackground};
  gap: 2px;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  &:first-of-type {
    position: sticky;
    left: 0;
  }
`
const Cell = styled.div`
  display: flex;
  flex: 1;
  min-height: 42px;
  padding: 10px 5px;
  min-width: 90px;
`
const Row = styled(Cell)<{ $colors?: Record<string, Colors> }>`
  align-items: center;
  justify-content: center;
  ${({ $colors }) =>
    $colors &&
    `
    background-color: ${$colors.background};
  `}
`
const Head = styled(Cell)<{ $isHorizontal: boolean; $isFirst: boolean }>`
  font-size: 13px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.tableBackground};
  white-space: nowrap;
  justify-content: flex-end;
  align-items: center;
  ${({ $isHorizontal }) =>
    $isHorizontal &&
    `
    justify-content: center;
    align-items: flex-end;
  `}
  ${({ $isFirst }) =>
    $isFirst &&
    `
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 600;
  `}
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

type Row = Array<{ displayValue: string; key?: string; color?: { background: Colors } }>

export function Summary({ patient }: { patient: PatientDetails }): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const [summary, summaryInterval, getSummaryByInterval, alerts] = useResultsStore((state) => [
    state.summary,
    state.summaryInterval,
    state.getSummaryByInterval,
    state.alerts,
  ])
  const [units, summarySettings, updateSummarySettings] = useUserStore((state) => [
    state.units,
    state.summarySettings,
    state.updateSummarySettings,
  ])
  const [isLoading, withLoading] = useLoading()

  const timeZoneCode = dayjs().tz(patient.timeZone).format('z')

  const [isInverted, setIsInverted] = useState(summarySettings?.isInverted)

  const columns = useMemo(() => {
    let timestamps = Object.keys(
      groupBy(
        summary.map((item) => ({
          ...item,
          timestamp: dayjs(item.timestamp).tz(patient?.timeZone).toISOString(),
        })),
        'timestamp',
      ),
    ).map((timestamp) => ({
      key: timestamp,
      displayValue: dayjs(timestamp).tz(patient.timeZone).format(t('dateFormats.dayAndMonthAndTime')),
    }))

    if (summaryInterval === SummaryIntervalEnum.Day) {
      timestamps = [...Array(14).keys()].map((days) => {
        const timestamp = dayjs().tz(patient.timeZone).subtract(days, 'day')

        return { key: timestamp.format('YYYY-MM-DD'), displayValue: timestamp.format(t('dateFormats.dayAndMonth')) }
      })
    }

    if (summaryInterval === SummaryIntervalEnum.Hour) {
      timestamps = [...Array(168).keys()].map((hours) => {
        const timestamp = dayjs().tz(patient.timeZone).subtract(hours, 'hours')

        return {
          key: timestamp.format('YYYY-MM-DDTHH'),
          displayValue: timestamp.format(t('dateFormats.dayAndMonthAndHour')),
        }
      })
    }

    const types = [...new Set(summary.map(({ type }) => isTrackingType(type) && type))]
      .filter(isTruthy)
      .sort()
      .map((type) => ({
        key: type,
        displayValue: `${getTrackingTitle(type)} ${units[type] ? `(${units[type].replace('_', ' ')})` : ''}`,
      }))

    const [horizontal, vertical] = isInverted ? [timestamps, types] : [types, timestamps]
    const columns: Array<Row> = [[{ displayValue: t('widgets.results.timeZone', { tz: timeZoneCode }) }]]

    horizontal.forEach(({ displayValue, key }) => columns[0].push({ displayValue, key }))

    vertical.forEach(({ key: verticalKey, displayValue }) => {
      const row: Row = [{ displayValue }]
      horizontal.forEach(({ key: horizontalKey }) => {
        const value = summary.find(
          ({ type, timestamp }) => [type, timestamp].includes(verticalKey) && [type, timestamp].includes(horizontalKey),
        )

        const color = { background: theme.tableBackgroundNoValue }

        if (value) {
          color.background = alerts.unreviewed.some(
            (alert) => alert.trackingEventId && value.trackingEventIds.includes(alert.trackingEventId),
          )
            ? theme.tableBackgroundConcerning
            : theme.tableBackgroundNonConcerning
        }

        row.push(
          value
            ? {
                displayValue: getDisplayValue(
                  [value.value ?? 0, value.additionalValue ?? 0],
                  value.unit ?? normalizedUnits[value.type],
                  value.type,
                  { targetUnit: units[value.type], omitUnit: true },
                ),
                color,
              }
            : { displayValue: '', color },
        )
      })

      columns.push(row)
    })

    return columns
  }, [summaryInterval, summary, isInverted, patient, alerts])

  const handleIntervalChange = (e: RadioChangeEvent): void => {
    const interval: SummaryIntervalEnum = e.target.value
    trackEvent(TrackingEvent.resultSummaryIntervalClicked, { interval })
    withLoading(getSummaryByInterval(patient.id, interval)).then((success) => {
      if (success) updateSummarySettings({ interval, isInverted })
    })
  }

  const handleToggleIsInverted = (): void => {
    updateSummarySettings({ interval: summaryInterval, isInverted: !isInverted })
    setIsInverted((prevState) => !prevState)
  }

  useEffect(() => {
    const localSummaryInterval = summarySettings?.interval
    trackEvent(TrackingEvent.resultSummaryIntervalInitialized, { interval: localSummaryInterval || summaryInterval })
    withLoading(getSummaryByInterval(patient.id, localSummaryInterval || summaryInterval))
  }, [])

  return (
    <>
      <Actions>
        <ReviewBulk type="summary" />
        <CreateVitalsReport />
      </Actions>
      <Controls>
        <Radio.Group
          options={[
            { value: SummaryIntervalEnum.Day, label: t('widgets.results.daily') },
            { value: SummaryIntervalEnum.Hour, label: t('widgets.results.hourly') },
            { value: SummaryIntervalEnum.All, label: t('widgets.results.all') },
          ]}
          onChange={handleIntervalChange}
          value={summaryInterval}
          optionType="button"
          buttonStyle="outline"
          size="middle"
        />
        <Tooltip title={t('widgets.results.invert')}>
          <Button
            style={{ marginLeft: '8px' }}
            shape="default"
            icon={<FontAwesomeIcon icon={faArrowsTurnToDots} />}
            onClick={handleToggleIsInverted}
          />
        </Tooltip>
        {summaryInterval !== SummaryIntervalEnum.All && <Description>{t('widgets.results.averages')}</Description>}
      </Controls>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isEmpty(summary) ? (
            <NoData />
          ) : (
            <Table>
              {columns.map((rows, columnIndex) => (
                <Column key={columnIndex}>
                  {rows.map(({ displayValue, color }, rowIndex) =>
                    columnIndex === 0 || rowIndex === 0 ? (
                      <Head
                        $isFirst={columnIndex === 0 && rowIndex === 0}
                        $isHorizontal={rowIndex === 0}
                        key={rowIndex}
                      >
                        {displayValue}
                      </Head>
                    ) : (
                      <Row key={rowIndex} $colors={color}>
                        {displayValue}
                      </Row>
                    ),
                  )}
                </Column>
              ))}
            </Table>
          )}
        </>
      )}
    </>
  )
}
