import { ThemeEnum, TunaAuthSuccessResponse } from '@vetahealth/tuna-can-api'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import { AppRouter } from './Router/AppRouter'
import { API, CallbackType, Websocket, getCurrentPatientId } from './lib/api'
import { SupportedLocale, antLocales, getLibLocale } from './lib/i18n'
import { storage } from './lib/storage'
import { Tokens } from './lib/storage/types'
import { getTheme } from './lib/theme'
import { resetTracking } from './lib/tracking'
import { useAppStore } from './stores/app'
import { useAuthStore } from './stores/auth'
import { useChatStore } from './stores/chat'
import { usePatientStore } from './stores/patient'
import { usePatientsStore } from './stores/patients'
import { useSitesStore } from './stores/sites'
import { useUserStore } from './stores/user'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
  .ant-select-dropdown .ant-select-item .ant-select-item-option-content {
    overflow: visible;
    white-space: normal;
  }
  .ant-dropdown.dropdown-scrollable .ant-dropdown-menu {
    max-height: 350px;
    overflow: auto;
  }
  .ant-table .ant-table-thead > tr > th {
    line-height: 1.4;
  }
  .ant-modal .ant-modal-body {
    margin-top: 16px;
  }

  .ant-timeline .ant-timeline-item .ant-timeline-item-label {
    width: calc(30% - 12px);
  }
  .ant-timeline .ant-timeline-item .ant-timeline-item-tail {
    left: 30%;
  }
  .ant-timeline .ant-timeline-item .ant-timeline-item-head {
    left: 30%;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(30% - 4px);
    width: calc(70% - 14px);
  }
  .ant-timeline .ant-timeline-item {
    max-width: 80%;
  }
  .ant-timeline .ant-timeline-item.ant-timeline-item-last {
    padding-bottom: 0;
  }

  .ant-form-item .ant-form-item-control {
    display: flex;
  }
  .ant-form-item .ant-form-item-control .ant-form-item-extra {
    order: 1;
    padding-bottom: 8px;
    margin-top: -6px;
    font-size: 13px;
  }
  .ant-form-item .ant-form-item-control .ant-form-item-control-input {
    order: 2;
  }
  .ant-form-item .ant-form-item-control
    :not(.ant-form-item-control-input, .ant-form-item-extra, .ant-form-item-explain, .ant-form-item-explain-error) {
    order: 3;
  }
  .ant-card .ant-card-body {
    padding: 20px;
  }
  .ant-popconfirm-message-icon {
    margin-right: 10px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  min-width: 800px;
`

export function App(): JSX.Element {
  const [locale, handleToken, resetUser, getSettings, updateLocale] = useUserStore((state) => [
    state.locale,
    state.handleToken,
    state.reset,
    state.getSettings,
    state.updateLocale,
  ])
  const [isAuthorized, isInitialized, setIsAuthorized, setIsUnauthorized, setInitialized] = useAuthStore((state) => [
    state.isAuthorized,
    state.isInitialized,
    state.setIsAuthorized,
    state.setIsUnauthorized,
    state.setInitialized,
  ])
  const [theme] = useUserStore((state) => [state.theme])
  const [getReleaseInfo, resetReleaseInfo] = useAppStore((state) => [state.getReleaseInfo, state.resetReleaseInfo])
  const [resetPatients] = usePatientsStore((state) => [state.reset])
  const [resetSites] = useSitesStore((state) => [state.reset])
  const [isChatOpen, getChatMessages, getUnreadChatMessages] = useChatStore((state) => [
    state.isChatOpen,
    state.getChatMessages,
    state.getUnreadChatMessages,
  ])
  const [patient] = usePatientStore((state) => [state.patient])

  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [systemTheme, setSystemTheme] = useState<ThemeEnum>(isDark ? ThemeEnum.Dark : ThemeEnum.Light)
  const { customTheme, antTheme } = getTheme(theme === ThemeEnum.System ? systemTheme : theme)

  async function handleIsAuthorized(accessToken: string): Promise<void> {
    setIsAuthorized()
    handleToken(accessToken)
    Websocket.connect(accessToken, getCurrentPatientId())
    await getSettings()
    await getReleaseInfo()
  }

  function handleIsUnauthorized(): void {
    setIsUnauthorized()
    Websocket.disconnect()
    resetPatients()
    resetSites()
    resetUser()
    resetReleaseInfo()
    resetTracking()
  }

  function handleSystemThemeChange(): void {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    setSystemTheme(isDark ? ThemeEnum.Dark : ThemeEnum.Light)
  }

  function handleStorageChange(event: StorageEvent): void {
    if (event.key === 'locale') updateLocale(event.newValue as SupportedLocale)
    if (event.key === 'tokens') {
      event.newValue ? handleIsAuthorized((JSON.parse(event.newValue) as Tokens).accessToken) : handleIsUnauthorized()
    }
  }

  function handleUserInfo({ info, permissions }: Pick<TunaAuthSuccessResponse, 'info' | 'permissions'>): void {
    useUserStore.getState().setUserInfo(info, permissions)
  }

  useEffect(() => {
    API.registerCallback<string>(CallbackType.onTokenRetrieved, handleIsAuthorized)
    API.registerCallback(CallbackType.onTokenExpired, handleIsUnauthorized)
    API.registerCallback<Pick<TunaAuthSuccessResponse, 'info' | 'permissions'>>(
      CallbackType.onInfoRetrieved,
      handleUserInfo,
    )

    API.initialize().then(setInitialized)

    window.onfocus = API.reInitialize

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleSystemThemeChange)

    // this only triggers for "inactive" tabs/windows
    window.addEventListener('storage', handleStorageChange)

    return () => {
      API.removeCallback(CallbackType.onTokenRetrieved, handleIsAuthorized)
      API.removeCallback(CallbackType.onTokenExpired, handleIsUnauthorized)
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleSystemThemeChange)
    }
  }, [])

  useEffect(() => {
    if (locale) {
      storage.updateLocale(locale)
      i18next.changeLanguage(locale)
      dayjs.locale(getLibLocale({ lib: 'dayjs', locale }))
    }
  }, [locale])

  useEffect(() => {
    function handleVisibilityChange(): void {
      if (document.visibilityState === 'hidden' || !isAuthorized) {
        Websocket.disconnect()
        return
      }

      // check if token is from another user (happens on sign-in in second tab) => reload to re-sync tab
      const tokens = storage.getTokens()
      if (!tokens) {
        handleIsUnauthorized()
        return
      }

      Websocket.connect(tokens.accessToken, getCurrentPatientId(), { isChatOpen })
      getUnreadChatMessages()
      if (isChatOpen && patient) {
        getChatMessages(patient.id)
      }

      const { sub } = jwtDecode(tokens.accessToken)
      const currentUserId = useUserStore.getState().userId
      if (currentUserId && currentUserId !== sub) window.location.reload()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isAuthorized, isChatOpen, patient])

  if (!isInitialized) return <div />

  return (
    <ConfigProvider
      theme={antTheme}
      locale={antLocales[getLibLocale({ lib: 'ant', locale })]}
      modal={{
        styles: {
          body: { overflowY: 'auto' },
          content: { display: 'flex', flex: 1, flexDirection: 'column', maxHeight: '100vh' },
          wrapper: { overflow: 'hidden' },
        },
      }}
    >
      <ThemeProvider theme={customTheme}>
        <GlobalStyle />
        <Wrapper>
          <AppRouter />
        </Wrapper>
      </ThemeProvider>
    </ConfigProvider>
  )
}
