import { faBars } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button, Dropdown, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'

import { isTruthy } from '@vetahealth/fishing-gear/lib/typeguards'
import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { routes } from '../../../Router/routes'
import { SiteManagementTab } from '../../../Router/routes/SiteManagement'
import { ModalName, useModalStore } from '../../../stores/modal'
import { useUserStore } from '../../../stores/user'

enum MenuKeys {
  addPatient = 'addPatient',
  careSummaryReport = 'careSummaryReport',
  emailSupport = 'emailSupport',
  importCsv = 'importCsv',
  manualOrders = 'manualOrders',
  resetDemoPatients = 'resetDemoPatients',
  resetUserSettings = 'resetUserSettings',
  preview = 'preview',
  userManagement = 'userManagement',
  siteManagement = 'siteManagement',
  roleManagement = 'roleManagement',
  userManual = 'userManual',
  releaseInfo = 'releaseInfo',
  archive = 'archive',
  analytics = 'analytics',
}

export function MainMenu(): JSX.Element {
  const { t } = useTranslation()
  const [hasPermission, hasSomePermission] = useUserStore((state) => [state.hasPermission, state.hasSomePermission])
  const [openModal] = useModalStore((state) => [state.openModal])
  const navigate = useNavigate()
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  function handleVisibilityChange(visible: boolean): void {
    setIsMenuVisible(visible)
    if (visible) setShowTooltip(false)
  }

  function handleSelection({ key }: { key: string }): void {
    setIsMenuVisible(false)
    switch (key) {
      case MenuKeys.addPatient:
        trackEvent(TrackingEvent.settingsMenuAddPatientClicked)
        openModal(ModalName.addPatient)
        return
      case MenuKeys.importCsv:
        trackEvent(TrackingEvent.settingsMenuImportPatientsClicked)
        navigate(routes.csvImport())
        return
      case MenuKeys.careSummaryReport:
        trackEvent(TrackingEvent.settingsMenuCareSummaryReportClicked)
        openModal(ModalName.reportWizard)
        return
      case MenuKeys.siteManagement:
        trackEvent(TrackingEvent.settingsMenuSiteMgmtClicked)
        navigate(routes.siteManagement(SiteManagementTab.programs))
        return
      case MenuKeys.userManagement:
        trackEvent(TrackingEvent.settingsMenuUserMgmtClicked)
        navigate(routes.userManagement())
        return
      case MenuKeys.roleManagement:
        trackEvent(TrackingEvent.settingsMenuRoleMgmtClicked)
        navigate(routes.roleManagement())
        return
      case MenuKeys.resetDemoPatients:
        openModal(ModalName.resetDemoPatients)
        return
      case MenuKeys.resetUserSettings:
        openModal(ModalName.resetUserSettings)
        return
      case MenuKeys.preview:
        openModal(ModalName.preview)
        return
      case MenuKeys.emailSupport:
        trackEvent(TrackingEvent.settingsMenuEmailSupportClicked)
        openModal(ModalName.emailSupport)
        return
      case MenuKeys.releaseInfo:
        trackEvent(TrackingEvent.settingsMenuReleaseInfoClicked)
        openModal(ModalName.releaseInfo)
        return
      case MenuKeys.userManual:
        trackEvent(TrackingEvent.settingsMenuUserGuideClicked)
        window.open(t('menus.main.userManualLinkTarget'), '_blank', 'noopener')?.focus()
        return
      case MenuKeys.manualOrders:
        trackEvent(TrackingEvent.settingsMenuManualOrdersClicked)
        navigate(routes.manualOrders())
        return
      case MenuKeys.archive:
        trackEvent(TrackingEvent.settingsMenuArchiveClicked)
        navigate(routes.archive())
        return
      case MenuKeys.analytics:
        trackEvent(TrackingEvent.settingsMenuAnalyticsClicked)
        navigate(routes.analytics())
        return
    }
  }

  const menuItems: any[] = [
    (hasSomePermission(PermissionName.accessPatient) || hasSomePermission(PermissionName.dispatchManualOrder)) && {
      type: 'group',
      key: 'patient',
      label: t('menus.main.patients'),
      children: [
        hasSomePermission(PermissionName.accessPatient) && {
          key: MenuKeys.addPatient,
          label: t('menus.main.addPatient'),
        },
        hasSomePermission(PermissionName.accessPatient) && {
          key: MenuKeys.importCsv,
          label: t('menus.main.importCsv'),
        },
        { key: MenuKeys.archive, label: t('menus.main.archive') },
        hasSomePermission(PermissionName.dispatchManualOrder) && {
          key: MenuKeys.manualOrders,
          label: t('menus.main.manualOrders'),
        },
        hasSomePermission(PermissionName.accessAnalytics) && {
          key: MenuKeys.analytics,
          label: t('menus.main.analytics'),
        },
      ],
    },
    hasSomePermission(PermissionName.publishBillingReport) && {
      type: 'group',
      key: 'reports',
      label: t('menus.main.reports'),
      children: [{ key: MenuKeys.careSummaryReport, label: t('menus.main.careSummaryReport') }],
    },
    (hasSomePermission(PermissionName.manageSite) ||
      hasSomePermission(PermissionName.manageUser) ||
      hasPermission(PermissionName.manageRole)) && {
      type: 'group',
      key: 'management',
      label: t('menus.main.management'),
      children: [
        hasSomePermission(PermissionName.manageSite) && {
          key: MenuKeys.siteManagement,
          label: t('menus.main.siteManagement'),
        },
        hasSomePermission(PermissionName.manageUser) && {
          key: MenuKeys.userManagement,
          label: t('menus.main.userManagement'),
        },
        hasPermission(PermissionName.manageRole) && {
          key: MenuKeys.roleManagement,
          label: t('menus.main.roleManagement'),
        },
      ],
    },
    hasSomePermission(PermissionName.testFeatures) && {
      type: 'group',
      key: 'testing',
      label: t('menus.main.testing'),
      children: [
        { key: MenuKeys.resetDemoPatients, label: t('menus.main.resetDemoPatients') },
        { key: MenuKeys.resetUserSettings, label: t('menus.main.resetUserSettings') },
        { key: MenuKeys.preview, label: t('menus.main.preview') },
      ],
    },
    {
      type: 'group',
      key: 'help',
      label: t('menus.main.help'),
      children: [
        { key: MenuKeys.userManual, label: t('menus.main.userManual') },
        { key: MenuKeys.emailSupport, label: t('menus.main.emailSupport') },
        {
          key: MenuKeys.releaseInfo,
          label: t('menus.main.releaseInfo'),
        },
      ],
    },
  ].filter(isTruthy)

  return (
    <div>
      <div onMouseEnter={() => setShowTooltip(!isMenuVisible)} onMouseLeave={() => setShowTooltip(false)}>
        <Dropdown
          menu={{ items: menuItems, onClick: handleSelection }}
          trigger={['click']}
          placement="bottomLeft"
          onOpenChange={handleVisibilityChange}
        >
          <Tooltip title={t('menus.main.title')} open={showTooltip} placement="bottomLeft">
            <Button
              shape="circle"
              type="text"
              onClick={() => trackEvent(TrackingEvent.settingsMenuClicked)}
              icon={<FontAwesomeIcon style={{ fontSize: 22 }} icon={faBars} />}
            />
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  )
}
